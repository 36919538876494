import app from './config/configApp';
import store from '@/vuex/store';
import './static/css/style.css';
import JsonExcel from "vue-json-excel3";
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/components/custom';
import '@/core/components/style';
import ToastPlugin from "vue-toast-notification";
import CKEditor from '@ckeditor/ckeditor5-vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import router from "@/routes/protectedRoute";
import axios from "axios";

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        store.dispatch('logOut')
        router.push('/auth/login')
    }
})

app.config.errorHandler = (err) => {
    console.log(err);
}

app.config.productionTip = false;
app.use(store);
app.use(router);
app.use(CKEditor);
app.use(ToastPlugin);
app.component("downloadExcel", JsonExcel);
app.mount('#app');
