export default [
  {
    name: 'events',
    path: '/app/events',
    component: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
    children: [
      {
        path: 'eventsTable',
        name: 'eventsTable',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/events/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/events/EventListDataTable.vue'),
        },
      },
      {
        path: 'add-event',
        name: 'add-event',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/events/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/events/AddEvent.vue'),
        },
        children: [
          {
            path: 'newevent',
            name: 'newevent',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/events/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/events/overview/NewEvent.vue'),
            },
          },
          {
            path: 'eventproducts',
            name: 'eventproducts',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/events/Index.vue'),
              descendant: () => import(/* webpackChunkName: "work" */ '../view/apps/events/overview/NewEventProducts.vue'),
            },
          },
        ],
      },
      {
        path: 'edit-event/:id',
        name: 'edit-event',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/events/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/events/EditEvent.vue'),
        },
        children: [
          {
            path: 'editevent',
            name: 'editevent',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/events/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/events/overview/EditFormEvent.vue'),
            },
          },
          {
            path: 'editeventproduct',
            name: 'editeventproduct',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/events/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/events/overview/EditEventProducts.vue'),
            },
          }
        ],
      },
      {
        path: 'disable-event/:id',
        name: 'disable-event',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/events/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/events/overview/DisableEvent.vue'),
        },
      }
    ],
  },
  {
    name: 'products',
    path: '/app/products',
    component: () => import(/* webpackChunkName: "users" */ '../view/apps/products/Index.vue'),
    children: [
      {
        path: 'productsTable',
        name: 'productsTable',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/products/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/products/ProductListDataTable.vue'),
        },
      },
      {
        path: 'add-product',
        name: 'add-product',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/products/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/products/AddProduct.vue'),
        },
        children: [
          {
            path: 'newproduct',
            name: 'newProduct',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/products/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/products/overview/NewProduct.vue'),
            },
          },
        ],
      },
      {
        path: 'edit-product/:id',
        name: 'edit-product',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/products/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/products/EditProduct.vue'),
        },
        children: [
          {
            path: 'editproduct',
            name: 'editproduct',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/products/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/products/overview/EditFormProduct.vue'),
            },
          }
        ],
      },
      {
        path: 'disable-product/:id',
        name: 'disable-product',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/products/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/products/overview/DisableProduct.vue'),
        },
      }
    ],
  },
  {
    name: 'hours',
    path: '/app/hours',
    component: () => import(/* webpackChunkName: "users" */ '../view/apps/hours/Index.vue'),
    children: [
      {
        path: 'hoursTable',
        name: 'hoursTable',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/hours/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/hours/HourListDataTable.vue'),
        },
      },
      {
        path: 'add-hour',
        name: 'add-hour',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/hours/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/hours/AddHour.vue'),
        },
        children: [
          {
            path: 'newhour',
            name: 'newHour',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/hours/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/hours/overview/NewHour.vue'),
            },
          },
        ],
      },
      {
        path: 'edit-hour/:id',
        name: 'edit-hour',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/hours/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/hours/EditHour.vue'),
        },
        children: [
          {
            path: 'edithour',
            name: 'edithour',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/hours/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/hours/overview/EditFormHour.vue'),
            },
          }
        ],
      },
      {
        path: 'disable-hour/:id',
        name: 'disable-hour',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/hours/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/hours/overview/DisableHour.vue'),
        },
      }
    ],
  },
  {
    name: 'users',
    path: '/app/users',
    component: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
    children: [
      {
        path: 'usersTable',
        name: 'usersTable',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/users/UserListDataTable.vue'),
        },
      },
      {
        path: 'add-user',
        name: 'add-user',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/users/AddUsers.vue'),
        },
        children: [
          {
            path: 'newuser',
            name: 'newUser',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/users/overview/NewUser.vue'),
            },
          },
        ],
      },
      {
        path: 'edit-user/:id',
        name: 'edit-user',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/users/EditUser.vue'),
        },
        children: [
          {
            path: 'edituser',
            name: 'editUser',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/users/overview/EditFormUser.vue'),
            },
          }
        ],
      },
      {
        path: 'disable-user/:id',
        name: 'disable-user',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/users/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/users/overview/DisableUser.vue'),
        },
      }
    ],
  },
  {
    name: 'orders',
    path: '/app/orders',
    component: () => import(/* webpackChunkName: "users" */ '../view/apps/orders/Index.vue'),
    children: [
      {
        path: 'ordersTable/:eventId',
        name: 'ordersTable',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/orders/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/orders/OrderListDataTable.vue'),
        },
      },
      {
        path: 'add-orders',
        name: 'add-orders',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/orders/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/orders/AddOrder.vue'),
        },
        children: [
          {
            path: 'neworder/:eventId',
            name: 'newOrder',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/orders/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/orders/overview/NewOrder.vue'),
            },
          },
        ],
      },
      {
        path: 'edit-order/:id/:eventId',
        name: 'edit-order',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/orders/Index.vue'),
          child: () => import(/* webpackChunkName: "addUser" */ '../view/apps/orders/EditOrder.vue'),
        },
        children: [
          {
            path: 'editorder',
            name: 'editOrder',
            components: {
              default: () => import(/* webpackChunkName: "users" */ '../view/apps/orders/Index.vue'),
              descendant: () => import(/* webpackChunkName: "info" */ '../view/apps/orders/overview/EditFormOrder.vue'),
            },
          }
        ],
      },
      {
        path: 'disable-order/:id',
        name: 'disable-order',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/orders/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/orders/overview/DisableOrder.vue'),
        },
      },
      {
        path: 'delete-order/:id/:eventId',
        name: 'delete-order',
        components: {
          default: () => import(/* webpackChunkName: "users" */ '../view/apps/orders/Index.vue'),
          child: () => import(/* webpackChunkName: "dataTable" */ '../view/apps/orders/overview/DeleteOrder.vue'),
        },
      }
    ],
  },
];
